import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactBingmaps } from 'react-bingmaps';
import useResizeObserverSafe from '../utils/useResizeObserverSafe';

const containerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '10px',
  overflow: 'hidden',
};

const restaurantLocation = [12.9716, 77.5946]; // Bangalore, Karnataka location
const deliveryLocation = [12.9816, 77.6046]; // Delivery location near Bangalore

// Function to get current driver position based on animation progress
const calculateDriverPosition = (progress) => {
  const waypoints = getPolyline().location;
  const totalSegments = waypoints.length - 1;
  
  // Determine which segment the driver is on
  const segmentIndex = Math.min(
    Math.floor(progress * totalSegments), 
    totalSegments - 1
  );
  
  // Calculate progress within the current segment (0-1)
  const totalDistance = totalSegments;
  const segmentStart = segmentIndex / totalDistance;
  const segmentEnd = (segmentIndex + 1) / totalDistance;
  const segmentProgress = (progress - segmentStart) / (segmentEnd - segmentStart);
  
  // Interpolate between the current segment's start and end points
  const startPoint = waypoints[segmentIndex];
  const endPoint = waypoints[segmentIndex + 1];
  
  return [
    startPoint[0] + (endPoint[0] - startPoint[0]) * segmentProgress,
    startPoint[1] + (endPoint[1] - startPoint[1]) * segmentProgress
  ];
};

// Function to format locations for Bing Maps infoboxes with pushpins
const getInfoBoxes = (driverPosition) => {
  const boxes = [
    {
      location: [restaurantLocation[0], restaurantLocation[1]],
      addHandler: "mouseover",
      infoboxOption: { 
        title: 'Restaurant', 
        description: 'Your order is being prepared here' 
      },
      pushPinOption: {
        text: '🍕', // Updated to pizza emoji for better restaurant representation
        title: 'Restaurant',
        textSize: 16,
        color: '#e53935'
      }
    },
    {
      location: [deliveryLocation[0], deliveryLocation[1]],
      addHandler: "mouseover",
      infoboxOption: { 
        title: 'Delivery Location', 
        description: 'Your order will be delivered here' 
      },
      pushPinOption: {
        text: '📍', // Updated to location pin for better visibility
        title: 'Delivery',
        textSize: 16,
        color: '#1e88e5'
      }
    }
  ];

  // Add the driver pushpin if driver position is available
  if (driverPosition) {
    boxes.push({
      location: driverPosition,
      addHandler: "mouseover",
      infoboxOption: { 
        title: 'Driver', 
        description: 'Your delivery is on the way!' 
      },
      pushPinOption: {
        text: '🛵', // Delivery scooter emoji
        title: 'Driver',
        textSize: 18,
        color: '#ff9800'
      }
    });
  }
  
  return boxes;
};

// Define polyline for the route with waypoints for a realistic road-like path
const getPolyline = () => {
  // Create waypoints to simulate a road network path
  const waypoints = [
    // Start at restaurant
    [restaurantLocation[0], restaurantLocation[1]],
    // First turn
    [restaurantLocation[0] + 0.002, restaurantLocation[1] + 0.001],
    // Second turn
    [restaurantLocation[0] + 0.003, restaurantLocation[1] + 0.004],
    // Intersection
    [restaurantLocation[0] + 0.005, restaurantLocation[1] + 0.008],
    // Main road
    [restaurantLocation[0] + 0.007, restaurantLocation[1] + 0.015],
    // Approaching destination neighborhood
    [deliveryLocation[0] - 0.002, deliveryLocation[1] - 0.004],
    // Final turn to destination
    [deliveryLocation[0] - 0.001, deliveryLocation[1] - 0.001],
    // Delivery location
    [deliveryLocation[0], deliveryLocation[1]]
  ];
  
  return {
    location: waypoints,
    option: { 
      strokeColor: 'blue',       // Clear blue color
      strokeThickness: 6,        // Thicker line for better visibility
      strokeDashArray: [1],      // Solid line
      visible: true              // Explicitly set to visible
    }
  };
};

// Generate the traveled portion of the route based on animation progress
const getTraveledPolyline = (progress) => {
  const allWaypoints = getPolyline().location;
  const totalSegments = allWaypoints.length - 1;
  
  // Determine which segment the driver is on
  const segmentIndex = Math.min(
    Math.floor(progress * totalSegments), 
    totalSegments - 1
  );
  
  // Calculate progress within the current segment (0-1)
  const segmentProgress = (progress * totalSegments) - segmentIndex;
  
  // Get all waypoints up to the current segment
  const traveledWaypoints = allWaypoints.slice(0, segmentIndex + 1);
  
  // Add the current position as the last point
  if (segmentIndex < totalSegments) {
    const startPoint = allWaypoints[segmentIndex];
    const endPoint = allWaypoints[segmentIndex + 1];
    
    const currentPosition = [
      startPoint[0] + (endPoint[0] - startPoint[0]) * segmentProgress,
      startPoint[1] + (endPoint[1] - startPoint[1]) * segmentProgress
    ];
    
    traveledWaypoints.push(currentPosition);
  }
  
  return {
    location: traveledWaypoints,
    option: { 
      strokeColor: '#ff4500',      // Orange-red for better tracking visibility
      strokeThickness: 8,          // Thicker line for emphasis
      strokeDashArray: [1],        // Solid line
      visible: true                // Explicitly set to visible
    }
  };
};

function TrackingScreen() {
  // Initialize with null to delay rendering the map until we have dimensions
  const [dimensions, setDimensions] = useState(null);
  const [status, setStatus] = useState('Preparing');
  const [driverPosition, setDriverPosition] = useState(null);
  const [animationProgress, setAnimationProgress] = useState(0);
  const [traveledRoute, setTraveledRoute] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const mapContainerRef = useRef(null);
  const animationRef = useRef(null);
  
  // Handle resize safely with our custom hook
  const handleResize = (entries) => {
    if (!entries || !entries[0]) return;
    
    const { width, height } = entries[0].contentRect;
    
    // Only update dimensions if they're actually changing
    setDimensions(prev => {
      if (!prev || Math.abs(prev.width - width) > 2 || Math.abs(prev.height - height) > 2) {
        return { width, height };
      }
      return prev;
    });
  };
  
  // Apply our safe resize observer to the map container
  const mapRef = useResizeObserverSafe(handleResize);
  
  // Safe tracking container ref
  const trackingRef = useResizeObserverSafe(() => {});

  // Animate driver along the route
  useEffect(() => {
    const startAnimation = () => {
      // Set initial driver position at restaurant
      setDriverPosition(restaurantLocation);
      setTraveledRoute(getTraveledPolyline(0));
      setLastUpdated(new Date());
      
      // Clear any existing animation
      if (animationRef.current) {
        clearInterval(animationRef.current);
      }
      
      // Start a new animation - updates every 2 minutes
      animationRef.current = setInterval(() => {
        setAnimationProgress(prev => {
          const newProgress = prev + 0.1; // Larger increment since updates are less frequent
          
          // Update driver position based on animation progress
          if (newProgress <= 1) {
            setDriverPosition(calculateDriverPosition(newProgress));
            setTraveledRoute(getTraveledPolyline(newProgress));
            setLastUpdated(new Date()); // Update timestamp on each movement
            return newProgress;
          } else {
            // Animation complete, stop interval
            clearInterval(animationRef.current);
            setTraveledRoute(getTraveledPolyline(1));
            setLastUpdated(new Date()); // Final update timestamp
            return 1;
          }
        });
      }, 120000); // Update every 2 minutes (120000ms)
    };

    // After a short delay to simulate order preparation, start the delivery
    const timer = setTimeout(() => {
      setStatus('On the way');
      startAnimation();
    }, 3000);
    
    return () => {
      clearTimeout(timer);
      if (animationRef.current) {
        clearInterval(animationRef.current);
      }
    };
  }, []);

  // Update order status when driver arrives
  useEffect(() => {
    if (animationProgress === 1) {
      setStatus('Delivered');
    } else if (animationProgress > 0) {
      setStatus('On the way');
    }
  }, [animationProgress]);

  // Separate effect to determine initial dimensions
  useEffect(() => {
    if (mapContainerRef.current && !dimensions) {
      const { offsetWidth, offsetHeight } = mapContainerRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, [mapContainerRef.current]);

  // Format the timestamp for display
  const formatLastUpdated = () => {
    if (!lastUpdated) return "Not yet updated";
    return lastUpdated.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <Box ref={trackingRef} style={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h4">Order Tracking</Typography>
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        Your order is on the way! Track it live below.
      </Typography>
      <Box style={{ marginTop: '20px' }}>
        <Typography variant="body2">Estimated Delivery Time: 30 minutes</Typography>
        <Typography variant="body2">Current Status: {status}</Typography>
        {lastUpdated && (
          <Typography variant="body2" style={{ color: '#666', marginTop: '5px' }}>
            Last Updated: {formatLastUpdated()}
          </Typography>
        )}
      </Box>

      {/* Map & Tracking UI - Only render when we have dimensions */}
      <Box 
        ref={(el) => {
          mapRef.current = el;
          mapContainerRef.current = el;
        }}
        style={containerStyle}
      >
        {dimensions && (
          <ReactBingmaps
            bingmapKey="ArW_TkF5xKfdKeIe4Ac-IQaAI7Mm3FLkRbPaj0g5EqEvF01MqV5JMR-ABy2BxrPd"
            center={[(restaurantLocation[0] + deliveryLocation[0]) / 2, 
                    (restaurantLocation[1] + deliveryLocation[1]) / 2]}
            infoboxesWithPushPins={getInfoBoxes(driverPosition)}
            polylines={traveledRoute ? [getPolyline(), traveledRoute] : [getPolyline()]}
            zoom={14} // Slightly zoomed in for better visibility
            mapOptions={{
              showMapTypeSelector: false,
              showZoomControls: true,
              mapTypeId: "road"  // Ensure we're using the road map type
            }}
            width={dimensions.width}
            height={dimensions.height}
            navigationBarMode={"compact"}
          />
        )}
      </Box>

      {/* Route Legend */}
      <Box 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: '20px', 
          marginTop: '10px',
          padding: '8px',
          backgroundColor: 'white',
          borderRadius: '5px'
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            style={{ 
              width: '20px', 
              height: '5px', 
              backgroundColor: 'blue', 
              marginRight: '8px' 
            }} 
          />
          <Typography variant="body2">Planned Route</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            style={{ 
              width: '20px', 
              height: '5px', 
              backgroundColor: '#ff4500', // Match the tracking line color
              marginRight: '8px' 
            }} 
          />
          <Typography variant="body2">Traveled Path</Typography>
        </Box>
      </Box>

      <Box 
        style={{ 
          backgroundColor: '#f5f5f5', 
          padding: '15px',
          borderRadius: '8px',
          marginTop: '10px'
        }}
      >
        <Typography variant="body1">
          {status === 'Preparing' && 'Your order is being prepared and will be ready shortly.'}
          {status === 'On the way' && 'Your order is on the way! The driver is heading to your location.'}
          {status === 'Delivered' && 'Your order has been delivered. Enjoy your meal!'}
        </Typography>
        <Box 
          style={{
            height: '10px',
            backgroundColor: '#e0e0e0',
            borderRadius: '5px',
            marginTop: '15px',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box 
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: status === 'Preparing' ? '30%' : 
                    status === 'On the way' ? `${30 + animationProgress * 60}%` :
                    '100%',
              backgroundColor: '#4caf50',
              transition: 'width 0.5s ease-in-out'
            }}
          />
        </Box>
        
        {/* Last Updated Indicator */}
        {lastUpdated && (
          <Box style={{ marginTop: '10px', textAlign: 'right' }}>
            <Typography variant="caption" style={{ fontStyle: 'italic' }}>
              Driver position updates every 2 minutes • Last update: {formatLastUpdated()}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TrackingScreen;
