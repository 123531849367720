import React from 'react';
import { InputBase, Paper, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '0 16px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 32px',
      maxWidth: '800px',
      margin: '0 auto',
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '2px 4px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '0.9rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  iconButton: {
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 8,
    },
  },
}));

export default function SearchBar({ searchQuery, onSearchChange, placeholder, themeColor }) {
  const classes = useStyles();

  const handleChange = (e) => {
    onSearchChange(e.target.value);
  };

  // Prevent form submission which would cause page refresh
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={classes.container}>
      <Paper 
        component="form" 
        className={classes.root} 
        elevation={2}
        onSubmit={handleSubmit}
      >
        <InputBase
          className={classes.input}
          placeholder={placeholder || "Search menu items..."}
          value={searchQuery}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'search menu items' }}
        />
        <IconButton 
          className={classes.iconButton} 
          aria-label="search" 
          style={{ color: themeColor }}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}
