import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Button, CircularProgress, Dialog,
  DialogTitle, } from '@material-ui/core';
import { setPaymentType, generateQrCode, getCheckoutUrl,setOrderType,setCustomerId } from '../actions';
import { Store } from '../Store';
import config,{ getParameterByName,merchantCode} from '../util';
import { ORDER_SET_SCHEDULE_DATE } from '../constants';
export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);
  let { userInfo } = state.userData;
  let customerInfo = sessionStorage.getItem("customerInfo");
  if(customerInfo){
    customerInfo = JSON.parse(customerInfo);
  }
  const styles = useStyles();
  const { selectedCurrency, order, qr } = state;

  const [userName,setUserName] = useState("");
  const [number,setNumber] =useState("");
  const [schedule,setSchedule]= useState("");
  const [address,setAddress] =useState(customerInfo.user.address);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);

  const [openForm,setOpenForm] = useState(false);
  const [openSign,setOpenSign] = useState(false);
  const [isPickUp,setIsPickUp] = useState(false);
  const [isDeliver,setIsDeliver] =useState(false);
  const [isScheduleDelivery, setIsScheduleDelivery] = useState(false);
 const themeColor = userInfo?.themeColor || '#ffbc01';  
  const themeTxtColor = userInfo?.themeTxtColor || '#000'; 
  let userData =sessionStorage.getItem("userInfo")?JSON.parse(sessionStorage.getItem("userInfo")):null;

  let {setting} = state.userSetting;
    console.log(setting);
  


useEffect(()=>{
  if(userData&&userData.length){
   console.log(userData);
   setCustomerId(dispatch,userData[0]._id)
  }
},[])


    if(setting){
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
   }




 let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;
 const baseURL =config.baseURL;
  const payURL = "https://pay.digitallive24.com";

  const getCurrency = userInfo? userInfo.currency:"";
  const getPercent = setting? setting.taxPercent:"";


  const [qrDetails, setQrDetails] = useState([])

  let getTime = new Date();
  let closeByTime = Math.floor(getTime.getTime() / 1000) + 180;
  console.log(closeByTime);

  // const [item,setItem] =useState([]);
console.log(state.order);
  let items = [];
  if (order) {
    order.orderItems.map((o) => {
      items.push({
        price_data: {
          currency: getCurrency,
          product_data: {
            name: o.name
          },
          unit_amount: o.price * 100 + (o.price * getPercent / 100) * 100
        },
        quantity: o.quantity
      })
    });
  }

  console.log(items);


  const selectHandler = (orderType) => {
    setOrderType(dispatch, orderType);
    // console.log(order)
    //   if (order.totalPrice) {
    //         getCheckoutUrl(dispatch, {
    //           items: items,
    //           success_url: window.location.origin + '/complete?' + window.location.href.split('?')[1],
    //           cancel_url: window.location.origin + '/?' + window.location.href.split('?')[1],
    //           userId: setting?setting.userId:"",
    //           appName: "sok",
    //           payType: "onetime",
    //           payStatus: "unpaid"
    //         });

    //     if(userData&&userData.length){

    //       return props.history.push('/payment?' + window.location.href.split('?')[1]);
    //     }else{
    //     console.log("pickup")
    //           if(orderType === "Pick Up"){
    //             setOpenForm(true);
    //             setIsPickUp(true);
    //             console.log("hello");
    //           }
    //           else{
    //             setOpenForm(true);
    //           setIsDeliver(true); 
    //         }
    //     }
    //   }

    if(orderType === "Pick Up"){
                setOpenForm(true);
                setIsPickUp(true);
                console.log("hello");
              }
              else if(orderType === "Schedule Delivery"){
                setOpenForm(true);
                setIsScheduleDelivery(true);
              }
              else{
                setOpenForm(true);
              setIsDeliver(true); 
            }

  };

//   const handleSubmit =()=>{
// //     // setCustomerId(dispatch,userData[0]._id);
// //     console.log("Submited");
// //     let orderItem =order.orderItems.map(o=>{
// //       return{
// //         name:o.name,
// //         price:o.price,
// //         unitQty:o.quantity,
// //         id:o.id
// //       }
// //     })
// // console.log(orderItem);
// // let data ={customer:{email: "ravi@gmail.com", firstName: "Ravi", id: "T3NPM7GNH7HST"},
// //             shoppingCart: {lineItems:orderItem}
// // }
// props.history.push('/complete?' + window.location.href.split('?')[1])
// //getCheckoutUrl(dispatch,{...data})

//     // if(address){
//     //   console.log("order Delvery to", address);

//     //   axios.post(`${config.authapi}/customer/auth-and-register`,{
//     //     email:`${number}@menulive.in` ,
//     //       phone:number ,
//     //       firstName:userName,
//     //       lastName: "",
//     //       address: " ",
//     //       password: number,
//     //       isEmailVerified:false ,
//     //       isPhoneVerified:false ,
//     //       referenceDetails:`Address : ${address}`,
//     //       merchantCode:merchantCode ,
//     //   }).then(res=>{
//     //     setCustomerId(dispatch,res.data.user.id);
//     //     setAddress("");
//     //     setNumber("");
//     //     setUserName("");
//     //     setSchedule("");
//     //     setIsPickUp(false);
//     //     setIsDeliver(false);
//     //     setOpenForm(false);
//     //     return props.history.push('/complete?' + window.location.href.split('?')[1])
//     //   })

     
//     // }else{
//     //   console.log("order pick");
//     //   axios.post(`${config.authapi}/customer/auth-and-register`,{
//     //     email:`${number}@menulive.in` ,
//     //       phone:number ,
//     //       firstName:userName,
//     //       lastName: "",
//     //       address: " ",
//     //       password: number,
//     //       isEmailVerified:false ,
//     //       isPhoneVerified:false ,
//     //       referenceDetails:`Schedule :${schedule}`,
//     //       merchantCode:merchantCode ,
//     //   }).then(res=>{
//     //     setCustomerId(dispatch,res.data.user.id);
//     //     setAddress("");
//     //     setNumber("");
//     //     setUserName("");
//     //     setSchedule("");
//     //     setOpenForm(false);
//     //     setIsPickUp(false);
//     //     setIsDeliver(false);
//     //     return props.history.push('/complete?' + window.location.href.split('?')[1])
//     //   })
      
//     //}

    

//   }
  
  const handleCancle =()=>{
          setOpenForm(false);
          setIsPickUp(false);
          setIsDeliver(false);
          setIsScheduleDelivery(false);
          setAddress("");
          setNumber("");
          setUserName("");
          setSchedule("");
          setUseCurrentLocation(false);
          return props.history.push('/?' + window.location.href.split('?')[1])
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Ideally, use reverse geocoding for a human-readable address
          setAddress(`Current Location (${latitude.toFixed(6)}, ${longitude.toFixed(6)})`);
          setIsLoadingLocation(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Unable to get your current location. Please enter address manually.");
          setIsLoadingLocation(false);
          setUseCurrentLocation(false);
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 0 }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setUseCurrentLocation(false);
    }
  };

  const handleCurrentLocationChange = (e) => {
    const checked = e.target.checked;
    setUseCurrentLocation(checked);
    if (checked) {
      getCurrentLocation();
    }
  };

  const handleSubmit = () => {
    console.log("Submitted with schedule:", schedule);

    const selectedDate = new Date(schedule);
    const selectedHour = selectedDate.getHours();

    if (selectedHour < 10 || selectedHour > 18) {
      alert("Pickup time must be between 10 AM and 6 PM.");
      return;
    }

    const timestamp = selectedDate.getTime();

    dispatch({
      type: ORDER_SET_SCHEDULE_DATE,
      payload: timestamp,
    });

    props.history.push('/complete?' + window.location.href.split('?')[1]);
};

  


  
  return (
    <Box style={{textAlign:"center",height:"100vh",backgroundColor:themeColor}}>

      <Dialog
        aria-labelledby="max-width-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth={state.widthScreen ? 'sm' : 'xs'}
        style={{backgroundColor:'#fff!important'}}
      >
        <DialogTitle>
          <div style={{
            fontSize:"1.3em", 
            fontWeight: "600", 
            borderBottom: "1px solid #eaeaea", 
            paddingBottom: "10px"
          }}>
            {order?.orderType ? `Order Type: ${order.orderType}` : "Order Type"}
          </div>
        </DialogTitle>
        
        <div style={{height:"auto", padding:"20px"}}>
          {/* Pickup Time Warning */}
          {isPickUp && 
            <div style={{
              marginBottom: "15px", 
              backgroundColor: "#fff8e6", 
              border: "1px solid #ffdb99", 
              borderRadius: "4px", 
              padding: "10px", 
              color: "#e67700"
            }}>
              <strong>Note:</strong> Pickup time is between 10 AM and 6 PM.
            </div>
          }
          
          {/* Schedule Delivery Warning */}
          {isScheduleDelivery && 
            <div style={{
              marginBottom: "15px", 
              backgroundColor: "#fff8e6", 
              border: "1px solid #ffdb99", 
              borderRadius: "4px", 
              padding: "10px", 
              color: "#e67700"
            }}>
              <strong>Note:</strong> Schedule your delivery time between 10 AM and 6 PM.
            </div>
          }
          
          {/* Name field - hidden */}
          {false && <span>
            <label style={{fontWeight: "bold"}} >Name <span style={{color:"red"}}>*</span>  </label>
            <input className='userInput' placeholder='Name' type='text' onChange={(e)=>setUserName(e.target.value)} />
          </span>}

          {/* Phone field - hidden */}
          {false && <span>
            <label style={{fontWeight: "bold"}} >Phone No <span style={{color:"red"}}>*</span> </label>
            <input type='text' className='userInput' placeholder='Mobile No'  onChange={(e)=>setNumber(e.target.value)} />
          </span>}

          {/* Pickup Schedule field */}
          {isPickUp && 
            <div style={{marginBottom: "15px"}}>
              <label style={{
                fontWeight: "bold", 
                display: "block", 
                marginBottom: "6px",
                fontSize: "0.95em"
              }}>
                Schedule <span style={{color:"red"}}>*</span>
              </label>
              <input 
                type='datetime-local' 
                className='userInput' 
                style={{
                  width: "100%", 
                  padding: "12px", 
                  border: "1px solid #ddd", 
                  borderRadius: "4px"
                }} 
                onChange={(e)=>setSchedule(e.target.value)} 
              />
            </div>
          }
          
          {/* Delivery Address Section */}
          {(isDeliver || isScheduleDelivery) && 
            <div style={{marginBottom: "15px"}}>
              <div style={{
                marginBottom: "12px", 
                display: "flex", 
                alignItems: "center", 
                backgroundColor: "#f9f9f9", 
                padding: "10px", 
                borderRadius: "4px"
              }}>
                <input 
                  type="checkbox" 
                  id="useCurrentLocation" 
                  checked={useCurrentLocation}
                  onChange={handleCurrentLocationChange}
                  style={{marginRight: "10px", transform: "scale(1.2)"}}
                />
                <label htmlFor="useCurrentLocation" style={{cursor: "pointer", fontSize: "0.95em"}}>
                  {isLoadingLocation ? 
                    <span style={{display: "flex", alignItems: "center"}}>
                      <span style={{marginRight: "10px"}}>Getting your location</span>
                      <CircularProgress size={16} />
                    </span> : 
                    "Use current location"
                  }
                </label>
              </div>
              
              <div>
                <label style={{
                  fontWeight: "bold", 
                  display: "block", 
                  marginBottom: "6px",
                  fontSize: "0.95em"
                }}>
                  Address <span style={{color:"red"}}>*</span>
                </label>
                <textarea 
                  className='userInput' 
                  placeholder='Enter delivery address' 
                  onChange={(e)=>setAddress(e.target.value)} 
                  value={address}
                  disabled={isLoadingLocation}
                  rows={3}
                  style={{
                    width: "100%", 
                    padding: "12px", 
                    border: "1px solid #ddd", 
                    borderRadius: "4px", 
                    resize: "vertical"
                  }}
                />
              </div>
            </div>
          }
          
          {/* Schedule Delivery Time field */}
          {isScheduleDelivery && 
            <div style={{marginBottom: "15px"}}>
              <label style={{
                fontWeight: "bold", 
                display: "block", 
                marginBottom: "6px",
                fontSize: "0.95em"
              }}>
                Schedule <span style={{color:"red"}}>*</span>
              </label>
              <input 
                type='datetime-local' 
                className='userInput'
                style={{
                  width: "100%", 
                  padding: "12px", 
                  border: "1px solid #ddd", 
                  borderRadius: "4px"
                }}
                onChange={(e)=>setSchedule(e.target.value)} 
              />
            </div>
          }
          
          {/* Action Buttons */}
          <div style={{
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            paddingTop: "20px", 
            marginTop: "10px",
            borderTop: "1px solid #eaeaea"
          }}>
            <button 
              className='c_btn'
              onClick={()=> handleCancle()}
              style={{
                padding: "10px 20px",
                minWidth: "100px"
              }}
            >
              Back
            </button>
            <button 
              className='s_btn' 
              onClick={handleSubmit}
              style={{
                padding: "10px 20px",
                minWidth: "100px",
                fontWeight: "bold"
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Dialog>
      
      <Box  style={{height:'100vh!important',backgroundColor:themeColor,padding:"20px"}}>
        <h2> Select Order Type</h2>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '15px'
        }}>
          <Card className={styles.card} style={{width: '200px', margin: '10px'}}>
            <CardActionArea onClick={() => selectHandler('Pick Up')} style={{height: '100%'}}>
              <CardMedia
                component="img"
                image="/images/pickup.png"
                className={styles.media}
                style={{height:"166px",width:"160px",margin:"0 auto"}}
              />
              <CardContent style={{padding: '16px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                  style={{margin: 0}}
                >
                  PICK UP
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card className={styles.card} style={{width: '200px', margin: '10px'}}>
            <CardActionArea onClick={() => selectHandler('Delivery')} id='counter' style={{height: '100%'}}>
              <CardMedia
                component="img"
                alt="At counter"
                image="/images/delivery.png"
                className={styles.media}
                style={{height:"166px",width:"160px",margin:"0 auto"}}
              />
              <CardContent style={{padding: '16px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                  style={{margin: 0}}
                >
                  DELIVERY
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card className={styles.card} style={{width: '200px', margin: '10px'}}>
            <CardActionArea onClick={() => selectHandler('Schedule Delivery')} style={{height: '100%'}}>
              <CardMedia
                component="img"
                alt="Schedule Delivery"
                image="/images/delivery.png"
                className={styles.media}
                style={{height:"166px",width:"160px",margin:"0 auto"}}
              />
              <CardContent style={{padding: '16px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                  style={{margin: 0}}
                >
                  SCHEDULE DELIVERY
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Box>
    </Box>
  );
}
