import React, { useContext, useEffect, useState } from "react";
import { Store } from "../Store";

import {
  addToOrder,
  clearOrder,
  listCategories,
  listProducts,
  removeFromOrder,
  getUserData,
} from "../actions";

import {
  Box,
  CircularProgress,
  Dialog,
  Slide,
} from "@material-ui/core";

import { useStyles } from "../styles";
import CartReview from "../components/Cart_Review";
import OrderCustomize from "../components/Order_Customize";
import CartButton from "../components/Cart_Button";
import BackButton from '../components/Back_Button';
import Categories from '../components/Categories';
import ProductList from '../components/Product_List';
import SearchBar from '../components/SearchBar'; // Import SearchBar
import FeaturedSlider from '../components/FeaturedSlider'; // Add this import
import config, { getParameterByName, merchantCode } from "../Configs";
import ReserveTable from './ReserveTable';
import SlidingPane from "react-sliding-pane";
import SignUp from './SignUp';
import { useIntl } from "react-intl";

export default function OrderScreen(props) {
  const styles = useStyles();
  const { formatMessage: t, locale, setLocale } = useIntl();
  const { state, dispatch } = useContext(Store);
  const { categories, loading, error } = state.categoryList;
  let {customizeInWizard} = state;
  const [orderItem, setOrderItem] = useState(null);
  let pgSets = {customizeInWizard};
  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = state.productList;

  const { orderItems, itemsCount, totalPrice, taxPrice, orderType } =
    state.order;
  const [quantity, setQuantity] = useState(1);
  const [selectedCat, setSelectedCat] = useState("");
  const[isReservePaneOpen, setReservePaneOpen]= useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  //const [isVegOnly, setIsVegOnly] = useState(false);

  //dialogs state
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const baseURL = config.baseURL;

  let { setting } = state.userSetting;
  let { userInfo } = state.userData;
  
  const themeColor = userInfo?.themeColor || '#ffbc01';  
  const themeTxtColor = userInfo?.themeTxtColor || '#000'; 
  
  let loggedUser = sessionStorage.getItem("customerInfo");
  const [isOpenSign, setOpenSign] = useState(false);

  const selectedCurrency = state.selectedCurrency;
  if (setting) {
    let body = document.getElementsByTagName("body");
    //body[0].style.backgroundColor = setting.color_tirnary;
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
  }

 useEffect(() => {
     listCategories(dispatch);
     listProducts(dispatch);
  }, []);

  const closeHandler = () => {
    setIsCustomizeOpen(false);
    setIsCartOpen(false);
  };

  const vegHandler = () => {
    console.log(products);
    //setIsVegOnly(!isVegOnly);
  };

const categoryClickHandler = (cat) => {
      console.log(cat);
      setSelectedCat(cat)
}

const addToOrderHandler = (customInstr) => {

  //Set price if variety price availabe
  let varName = Object.keys(orderItem.sub_pro.variety);
  orderItem.price = varName.length
      ? parseFloat(orderItem.sub_pro.variety[varName[0]])
      : orderItem.price;

  customInstr.length&& orderItem.sub_pro.cookInstructions.push(customInstr);
  console.log(orderItem);
  
  addToOrder(dispatch, { ...orderItem, quantity });

  setIsCustomizeOpen(false);
  setOrderItem(null);

  let isEditOrdItem=orderItems.filter(om=> om.id==orderItem.id).length;
  isEditOrdItem && setIsCartOpen(true);

  };



const productClickHandler = (p,isEditOrder) => {
    if(!isEditOrder){
    p['sub_pro']={};
    p.sub_pro['addons'] = [];
    p.sub_pro['variety'] = {};
    p.sub_pro['cookInstructions'] =[];
    }
    setOrderItem(p)
    setIsCustomizeOpen(!isCustomizeOpen);
  };

  const removeOrderItm = () => {
    setIsCustomizeOpen(false);
    setOrderItem(null);
    removeFromOrder(dispatch, orderItem);
  };

  const procedToCheckoutHandler = () => {
    let loginData = sessionStorage.getItem('customerInfo');
    loginData = JSON.parse(loginData);
    if (loginData !== null) {
      props.history.push("/select-payment?"+ window.location.href.split('?')[1]);
    } else {
      setOpenSign(true);
      setIsCartOpen(false) 
    }
    //props.setIsPaneOpen(false);
  };

  const previewOrderHandler = () => {
    props.history.push("/complete?" + window.location.href.split("?")[1]);
  };

  const handleCart = (cartItems) => {
    console.log("Cart");
    if(cartItems==0)return
    setIsCartOpen(true);
  };

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  };

  const handleBack =()=>{
    if(isCartOpen||isCustomizeOpen){
        setIsCartOpen(false);
        setIsCustomizeOpen(false);
    }else{
    props.history.push(`/choose?` + window.location.href.split("?")[1]);
    }
  }

let slideChild=[];
 
  // Filter products based on search query
  const filteredProducts = products ? products.filter(product => 
    product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (product.description && product.description.toLowerCase().includes(searchQuery.toLowerCase()))
  ) : [];
 
  return (
    <Box className={styles.root} style={{ backgroundColor: "#fff" }}>
  <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isReservePaneOpen}
        width="50%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setReservePaneOpen(false);
        }}
      >
       <div style={{padding:"20px"}}>
          <ReserveTable setReservePaneOpen={setReservePaneOpen} />
       </div>

      </SlidingPane>
      <Categories 
        categories={categories?categories:[]} 
        baseURL={baseURL}
        selectedCat={selectedCat}
        imageOnErrorHandler={imageOnErrorHandler}
        customizeInWizard={customizeInWizard}
        pgSets={pgSets}
        categoryClickHandler={categoryClickHandler}/>
<h2 style={{margin:"2px"}}>{(selectedCat && selectedCat.name) || t({ id: "explore_menu" })}</h2>

 {/* SearchBar positioned before heading for better UI flow */}
 <SearchBar 
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        placeholder={t({ id: 'search_menu' }) || "Search menu items..."}
        themeColor={themeColor}
      />
      
      {/* Add the Featured Slider here */}
      <FeaturedSlider 
        products={products || []} 
        baseURL={baseURL}
        imageOnErrorHandler={imageOnErrorHandler}
        themeColor={themeColor}
      />
      
      
     
      <ProductList 
        items={searchQuery ? filteredProducts : (products || [])} 
        baseURL={baseURL}
        pgSets={pgSets}
        imageOnErrorHandler={imageOnErrorHandler}
        productClickHandler={productClickHandler}
        categories={categories?categories:[]}
        selectedCat={selectedCat}
        style={{ backgroundColor:themeColor}}
        t={t}/>
     
      <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
         
          open={isCartOpen||isCustomizeOpen||isOpenSign}
          fullWidth={true}
        maxWidth={'xl'}
          
        >
        
         {isCustomizeOpen&& <OrderCustomize 
        orderItem={orderItem}
        setOrderItem={setOrderItem}
        baseURL={baseURL}
        imageOnErrorHandler={imageOnErrorHandler}
        quantity ={quantity}
        categories={categories}
        setQuantity={setQuantity}
        orderItems={orderItems}
        categories={categories}
        items={products?products:[]}
        setIsCustomizeOpen={setIsCustomizeOpen}
        addToOrderHandler={addToOrderHandler}
        removeOrderItm={removeOrderItm}
        t={t}
        style={{ backgroundColor:themeColor}}
        />

        }
        {isCartOpen&& <CartReview t={t}  
        orderItems={orderItems}
        setIsCartOpen={setIsCartOpen}
        productClickHandler={productClickHandler}
        categories={categories}
        procedToCheckoutHandler={procedToCheckoutHandler}
        style={{ backgroundColor: themeColor }}
        />}

        {
          isOpenSign &&  <SignUp log={isOpenSign} setOpenSign={setOpenSign}  procedToCheckoutHandler={procedToCheckoutHandler}/>
        }

        </Dialog>

        {itemsCount && <CartButton itemsCount={itemsCount} 
        handleCart={handleCart}
        setIsCartOpen={setIsCartOpen}/>}         
      </Box>
  );
}
