import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Fixed imports with more reliable default images
import defaultFoodImage from '../assets/image/pro_1739120931166lemn (1).JPG';
import defaultFoodImage1 from '../assets/image/pro_1739121005655mosabi.JPG';
import defaultFoodImage2 from '../assets/image/pro_1739121155469red-banana.JPG';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    width: '94%',
    height: '220px',
    overflow: 'hidden',
    margin: '0% auto 0px auto', // Position 10% down from the top
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: { // Mobile styling
      height: '350px',
      width: '92%',
      margin: '1% auto 16px auto',
    },
    [theme.breakpoints.up('md')]: { // Tablet styling
      height: '450px',
    },
    [theme.breakpoints.up('lg')]: { // Desktop styling
      height: '550px',
      maxWidth: '1200px',
      margin: '1% auto 24px auto',
    },
  },
  slide: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    transition: 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out',
  },
  slideImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 1,
  },
  slideContent: {
    position: 'relative',
    zIndex: 2,
    color: '#fff',
    padding: theme.spacing(2),
    background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
    [theme.breakpoints.down('sm')]: { // Smaller padding on mobile
      padding: theme.spacing(1.5),
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: '1.2rem',
    textShadow: '1px 1px 3px rgba(0,0,0,0.6)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    fontSize: '0.9rem',
    textShadow: '1px 1px 2px rgba(0,0,0,0.6)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  dots: {
    position: 'absolute',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: 3,
  },
  dot: {
    height: '8px',
    width: '8px',
    margin: '0 4px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    [theme.breakpoints.up('md')]: {
      height: '10px',
      width: '10px',
      margin: '0 5px',
    },
  },
  activeDot: {
    backgroundColor: '#fff',
  },
  featuredTag: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(255, 187, 0, 0.85)',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    zIndex: 3,
    [theme.breakpoints.up('md')]: {
      padding: '5px 10px',
      fontSize: '0.85rem',
    },
  }
}));

const FeaturedSlider = ({ products, baseURL, imageOnErrorHandler, themeColor }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageErrors, setImageErrors] = useState({});
  
  // Get featured products or use first few products
  const featuredProducts = products && products.length > 0 
    ? products.filter(p => p.image && p.image.trim() !== '').slice(0, 5) // Use only products with valid images
    : [];

  // Function to get a random default image
  const getRandomDefaultImage = () => {
    const defaultImages = [defaultFoodImage, defaultFoodImage1, defaultFoodImage2];
    const randomIndex = Math.floor(Math.random() * defaultImages.length);
    return defaultImages[randomIndex];
  };

  // Enhanced image source handling
  const getImageSource = (product) => {
    // If product has no image or we've recorded an error for this product, return a default image
    if (!product || !product.image || product.image.trim() === '' || imageErrors[product.id]) {
      return getRandomDefaultImage();
    }
    
    // If baseURL is missing, use a default image
    if (!baseURL) {
      console.warn('baseURL is undefined, using default image');
      return getRandomDefaultImage();
    }
    
    // Return the proper image URL
    return `${baseURL}/assets/uploads/menu_images/${product.image}`;
  };

  useEffect(() => {
    if (featuredProducts.length <= 1) return;
    
    const interval = setInterval(() => {
      setActiveIndex((current) => 
        current === featuredProducts.length - 1 ? 0 : current + 1
      );
    }, 3000); // Change slide every 3 seconds
    
    return () => clearInterval(interval);
  }, [featuredProducts.length]);

  if (!featuredProducts.length) return null;

  return (
    <Box className={classes.sliderContainer}>
      <Box className={classes.featuredTag} style={{ backgroundColor: themeColor || 'rgba(255, 187, 0, 0.85)' }}>
        Featured
      </Box>
      {featuredProducts.map((product, index) => (
        <Box 
          key={product.id || index} 
          className={classes.slide}
          style={{
            transform: `translateX(${(index - activeIndex) * 100}%)`,
            opacity: index === activeIndex ? 1 : 0,
          }}
        >
          <img 
            src={getImageSource(product)}
            alt={product.name || "Featured item"}
            onError={(e) => {
              // Record this error to prevent retrying the same failed image
              if(product && product.id) {
                setImageErrors(prev => ({...prev, [product.id]: true}));
              }
              
              if (imageOnErrorHandler) {
                imageOnErrorHandler(e);
              } else {
                e.target.onerror = null;
                e.target.src = getRandomDefaultImage();
              }
            }}
            className={classes.slideImage}
          />
          <Box className={classes.slideContent}>
            <Typography variant="h6" className={classes.title}>
              {product.name}
            </Typography>
            {product.description && (
              <Typography variant="body2" className={classes.description}>
                {product.description.substring(0, 60)}
                {product.description.length > 60 ? '...' : ''}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      
      <Box className={classes.dots}>
        {featuredProducts.map((_, index) => (
          <Box 
            key={index} 
            className={`${classes.dot} ${index === activeIndex ? classes.activeDot : ''}`} 
            onClick={() => setActiveIndex(index)}
            style={{ backgroundColor: index === activeIndex ? themeColor || '#ffbc01' : 'rgba(255, 255, 255, 0.5)' }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FeaturedSlider;
